import { moduleLogger } from "./util/Logger";
import { type Action, type Feature, submitEvent, submitMerge, submitMove } from "@otto-ec/tracking-bct";

const log = moduleLogger.scope("tracking");

export class TrackingService {
	sendEvent(payload: Action) {
		log.info("Send 'event'", payload);
		submitEvent({}, payload);
	}

	sendMove(payload: Action) {
		log.info(`Send 'move event'`, payload);
		submitMove({}, payload);
	}

	sendMergeForCinema(payload: Feature[]) {
		log.info("Send 'merge for cinema'", payload);
		submitMerge({}, payload);
	}
}
